import React from 'react'
import LoginForm from '../components/Login/LoginForm'

const login: React.FC = () => {
  return (
    <>
      <LoginForm />
    </>
  )
}

export default login