export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {}

const setUser = (user: any) => {
  if (isBrowser()) {
    window.localStorage.setItem('gatsbyUser', JSON.stringify(user))
  }
}


export const handleLogin = (data: any) => {

  if (data.email === `socios@kushki.com` && data.password === `R0nc0rr0rr1nc02022`) {
    setUser({
      email: data.email,
      session: true,
    })
    return true
  } else {
    setUser({})
    return false
  }
}


export const isLoggedIn = () => {
  const user = getUser()
  return Object.keys(user).length !== 0
}

export const logout = () => {
  return setUser({})
}