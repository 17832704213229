import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../../services/auth'

const LoginForm = () => {
  const [ stateUser, setStateUser ] = useState({})

  const handleUpdate = (event: any) => {
    setStateUser({
      ...stateUser,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleLogin(stateUser)
    if (!handleLogin(stateUser)) {
      navigate('/404')
    } else {
      navigate('/')
    }
  } 

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
              <form
                className="card blog-subscribe-form"
                method="post"
                onSubmit={event => {
                  handleSubmit(event)
                }}
              >
                <div className="card-body">
                  <div className="py-2">
                    <input type="text" placeholder="Email" name="email" onChange={handleUpdate}  />
                  </div>
                  <div className="py-1">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleUpdate}
                    />
                  </div>
                  <div className="py-2">
                    <input type="submit" className="btn--primary" value="Log In" />
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginForm
